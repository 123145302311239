<template>
  <b-form-group
    :state="state"
    :description="description"
    :label-class="{
      'auth-label': true,
      'auth-label--active': isActive,
    }"
    :label="label"
    class="auth-input-container"
  >
    <div class="inputs-container">
      <div
        :class="{
          'auth-input': true,
          'auth-input--error': isError,
          'auth-input--success': isSuccess,
        }"
      >
        <b-form-input
          v-model="computedValue"
          ref="input"
          :name="name"
          :type="inputType"
          @change="$emit('change')"
          @keypress.enter="onBlur"
          @blur="onBlur"
          @focus="onFocus"
        />

        <div
          v-if="type === 'password'"
          class="auth-input__icon-right"
          @click="isPasswordShown = !isPasswordShown"
        >
          <EyeIcon v-if="isPasswordShown" />
          <EyeSlashIcon v-else />
        </div>
      </div>
      <RadioButton
        v-if="showCheckBox"
        class="mr-3"
        :checked="answerItem.is_correct"
        :isTypeCheckBox="question.meta.is_multiple"
        @change="markAnswerAsCorrect"
      />
    </div>
    <div
      class="invalid-feedback"
      :class="{ 'invalid-feedback--active': isError && invalidFeedback }"
    >
      <transition name="component-fade" mode="out-in">
        <span v-if="isError && invalidFeedback" class="d-block">{{ invalidFeedback }}</span>
      </transition>
    </div>
  </b-form-group>
</template>

<script>
import RadioButton from '@/components/Buttons/RadioButton.vue';
import EyeIcon from '@/components/Icons/EyeIcon.vue';
import EyeSlashIcon from '@/components/Icons/EyeSlashIcon.vue';

export default {
  components: { EyeIcon, EyeSlashIcon, RadioButton },
  props: {
    type: {
      type: String,
      default: 'text',
    },
    label: {
      type: String,
      required: true,
    },
    state: {
      type: Boolean,
      default: null,
    },
    invalidFeedback: {
      type: String,
    },
    description: {
      type: String,
    },
    name: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      default: '',
    },
    showCheckBox: {
      type: Boolean,
      default: false,
    },
    answerItem: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    isActive: false,
    isPasswordShown: false,
  }),
  inject: {
    question: {
      default: () => ({}),
    },
    updateAnswers: {
      default: () => ({}),
    },
  },
  computed: {
    inputType() {
      if (this.type === 'password') {
        if (this.isPasswordShown) {
          return 'text';
        }
        return this.type;
      }

      return this.type;
    },
    computedValue: {
      get() {
        return this.value;
      },
      set() {},
    },
    computedCheckbox: {
      get() {
        return true;
      },
      set() {},
    },
    isSuccess() {
      return this.state === true;
    },
    isError() {
      return this.state === false;
    },
  },
  mounted() {
    this.autocompleteCheckingInterface();
  },
  methods: {
    onFocus() {
      this.isActive = true;

      this.$emit('focus');
    },

    onBlur(e) {
      if (!e.target.value) {
        this.isActive = false;
      }

      this.$emit('blur', e);
      e.target.blur();
    },

    markAnswerAsCorrect() {
      this.updateAnswers(this.answerItem.id);
    },

    autocompleteCheckingInterface() {
      // each 100ms we check if the issue was produced
      let intervalDetectAutofill = setInterval(() => {
        if (
          // we target at least one of the stuff that will be affected by autofill
          // to do our checking
          document.querySelectorAll('input[type="password"]:-webkit-autofill').length > 0
        ) {
          // and we inform the system about the issue if it is produced
          this.isActive = true;

          // we stop to check if issue was produced
          clearInterval(intervalDetectAutofill);
        }
      }, 100);

      // if after 3s nothing appear, means no autofill was made
      setTimeout(() => {
        if (intervalDetectAutofill) {
          clearInterval(intervalDetectAutofill);
          intervalDetectAutofill = null;
        }
      }, 3000);
    },
  },
  watch: {
    value: {
      handler(val) {
        this.isActive = !!val;
      },
      immediate: true,
    },
  },
};
</script>
