var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-form-group',{staticClass:"auth-input-container",attrs:{"state":_vm.state,"description":_vm.description,"label-class":{
    'auth-label': true,
    'auth-label--active': _vm.isActive,
  },"label":_vm.label}},[_c('div',{staticClass:"inputs-container"},[_c('div',{class:{
        'auth-input': true,
        'auth-input--error': _vm.isError,
        'auth-input--success': _vm.isSuccess,
      }},[_c('b-form-input',{ref:"input",attrs:{"name":_vm.name,"type":_vm.inputType},on:{"change":function($event){return _vm.$emit('change')},"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.onBlur.apply(null, arguments)},"blur":_vm.onBlur,"focus":_vm.onFocus},model:{value:(_vm.computedValue),callback:function ($$v) {_vm.computedValue=$$v},expression:"computedValue"}}),(_vm.type === 'password')?_c('div',{staticClass:"auth-input__icon-right",on:{"click":function($event){_vm.isPasswordShown = !_vm.isPasswordShown}}},[(_vm.isPasswordShown)?_c('EyeIcon'):_c('EyeSlashIcon')],1):_vm._e()],1),(_vm.showCheckBox)?_c('RadioButton',{staticClass:"mr-3",attrs:{"checked":_vm.answerItem.is_correct,"isTypeCheckBox":_vm.question.meta.is_multiple},on:{"change":_vm.markAnswerAsCorrect}}):_vm._e()],1),_c('div',{staticClass:"invalid-feedback",class:{ 'invalid-feedback--active': _vm.isError && _vm.invalidFeedback }},[_c('transition',{attrs:{"name":"component-fade","mode":"out-in"}},[(_vm.isError && _vm.invalidFeedback)?_c('span',{staticClass:"d-block"},[_vm._v(_vm._s(_vm.invalidFeedback))]):_vm._e()])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }