<template>
  <div class="auth-container">
    <div class="auth-form">
      <transition name="component-fade" mode="out-in">
        <b-form
          v-if="!isSuccessfullySend"
          key="forgot"
          @submit.prevent="onSubmit"
          class="auth-form__body"
        >
          <div class="auth-form__title">
            <p>{{ $t('buttonLabels.forgotPassword') }}</p>
          </div>
          <div class="auth-form__subtitle mb-3">
            <p>{{ $t('supportText.enterEmailToSendLink') }}</p>
          </div>

          <AppInput
            ref="email"
            name="email"
            :label="$t('label.email')"
            :invalidFeedback="error"
            :state="$v.email.$error || error ? false : null"
            @blur="setEmail"
          />
          <div class="recover f-center mt-3 mb-0">
            <span class="link link--color-gray" @click="$router.push('/auth')">{{
              $t('buttonLabels.back')
            }}</span>
          </div>
          <b-button class="mt-3" type="submit" variant="primary" :disabled="loading">
            <span v-if="!loading">{{ $t('buttonLabels.resetPassword') }}</span>
            <span v-else>
              <b-spinner small type="grow"></b-spinner>
              {{ $t('label.processing') }}
            </span>
          </b-button>
        </b-form>
        <b-form v-else key="back" @submit.prevent="goBack" class="auth-form__body">
          <div class="auth-form__subtitle">
            <p>{{ $t('supportText.weSentLinkToEmail') }}</p>
          </div>
          <b-button class="mt-3" type="submit" variant="primary" :disabled="loading">
            <span>{{ $t('buttonLabels.back') }}</span>
          </b-button>
        </b-form>
      </transition>
    </div>
  </div>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators';
import AppInput from '@/components/AppInput.vue';
import AuthService from '@/services/auth.service';

export default {
  name: 'ForgotPassword',
  components: { AppInput },
  data: () => ({
    email: '',
    error: null,
    loading: false,
    isSuccessfullySend: false,
  }),
  validations: {
    email: {
      required,
      email,
    },
  },
  methods: {
    setEmail(e) {
      if (e.target.value) {
        this.email = e.target.value;
        this.isFormValid();
      }
    },
    onSubmit() {
      if (this.isFormValid()) {
        this.loading = true;
        this.error = null;

        AuthService.forgotPassword({ email: this.email, locale: this.$i18n.locale })
          .then(() => (this.isSuccessfullySend = true))
          .catch((error) => {
            const status = error.response?.status;

            if (status === 404) {
              this.error = this.$t('errorMessages.emailNotFound');
            } else if (status === 401) {
              this.error = this.$t('errorMessages.emailNotConfirmed');
            } else {
              this.error = this.$t('errorMessages.somethingWentWrong');
            }
          })
          .finally(() => (this.loading = false));
      }
    },
    isFormValid() {
      this.error = null;
      this.$v.$reset();
      this.$v.email.$touch();
      if (!this.$v.email.$model.length) {
        this.error = this.$t('errorMessages.enterEmail');
        return false;
      }

      if (this.$v.email.$invalid) {
        this.error = this.$t('errorMessages.invalidEMail');
        return false;
      }
      return true;
    },
    goBack() {
      this.$router.push('/auth');
    },
  },
};
</script>
